@import '/src/style/variables';

@keyframes spin {
  80% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loading {
  animation-duration: $timing-slow;
  animation-iteration-count: infinite;
  animation-name: spin;
  animation-direction: alternate-reverse;
}
