$color-orange: #ff5d28;
$color-orange-lightest: #ffefe9;
$color-berry: #ff0e65;
$color-berry-lightest: #ffe7f0;
$color-fuschia: #da00ed;
$color-fuschia-lightest: #fbe5fd;
$color-purple: #7e19ff;
$color-purple-lightest: #f2e8ff;
$color-indigo: #3531ff;
$color-indigo-lightest: #ebeaff;
$color-blue: #0085ff;
$color-blue-lightest: #e5f3ff;
$color-cyan: #00c2ff;
$color-cyan-lightest: #e5f9ff;
$color-teal: #00cfcf;
$color-teal-lightest: #e5fdfd;
$color-mint: #00e090;
$color-mint-lightest: #e5fef4;
$color-green: #3dc000;
$color-green-lightest: #ecf9e5;
$color-lime: #9acc0b;
$color-lime-lightest: #f8fee4;
$color-golden: #ffa800;
$color-golden-lightest: #fff7db;

$color-gray-dark: #2e2e2e;
$color-gray-light: #969696;
$color-gray-lighter: #dedede;
$color-black: #000;
$color-white: #fff;

$themes: (
  orange: (
    color-primary: $color-orange,
    color-primary-dark: #df3f0a,
    color-primary-darkest: #df3f0a,
    color-primary-light: #ffbea9,
    color-primary-lightest: $color-orange-lightest,
    color-font: $color-white
  ),
  berry: (
    color-primary: $color-berry,
    color-primary-dark: #d60a54,
    color-primary-darkest: #d60a54,
    color-primary-light: #ffb7d1,
    color-primary-lightest: $color-berry-lightest,
    color-font: $color-white
  ),
  fuschia: (
    color-primary: $color-fuschia,
    color-primary-dark: #b411c3,
    color-primary-darkest: #b411c3,
    color-primary-light: #f6bffa,
    color-primary-lightest: $color-fuschia-lightest,
    color-font: $color-white
  ),
  purple: (
    color-primary: $color-purple,
    color-primary-dark: #6013c3,
    color-primary-darkest: #6013c3,
    color-primary-light: #dfc6ff,
    color-primary-lightest: $color-purple-lightest,
    color-font: $color-white
  ),
  indigo: (
    color-primary: $color-indigo,
    color-primary-dark: #2825bf,
    color-primary-darkest: #2825bf,
    color-primary-light: #c2c1ff,
    color-primary-lightest: $color-indigo-lightest,
    color-font: $color-white
  ),
  blue: (
    color-primary: $color-blue,
    color-primary-dark: #0070d6,
    color-primary-darkest: #0070d6,
    color-primary-light: #b3daff,
    color-primary-lightest: $color-blue-lightest,
    color-font: $color-white
  ),
  cyan: (
    color-primary: $color-cyan,
    color-primary-dark: #0098da,
    color-primary-darkest: #0098da,
    color-primary-light: #b3edff,
    color-primary-lightest: $color-cyan-lightest,
    color-font: $color-white
  ),
  teal: (
    color-primary: $color-teal,
    color-primary-dark: #00a6a6,
    color-primary-darkest: #00a6a6,
    color-primary-light: #b3f1f1,
    color-primary-lightest: $color-teal-lightest,
    color-font: $color-white
  ),
  mint: (
    color-primary: $color-mint,
    color-primary-dark: #00b374,
    color-primary-darkest: #00b374,
    color-primary-light: #b3f6de,
    color-primary-lightest: $color-mint-lightest,
    color-font: $color-white
  ),
  green: (
    color-primary: $color-green,
    color-primary-dark: #319a00,
    color-primary-darkest: #319a00,
    color-primary-light: #beecb3,
    color-primary-lightest: $color-green-lightest,
    color-font: $color-white
  ),
  lime: (
    color-primary: $color-lime,
    color-primary-dark: #7aa511,
    color-primary-darkest: #7aa511,
    color-primary-light: #e1f0b6,
    color-primary-lightest: $color-lime-lightest,
    color-font: $color-white
  ),
  golden: (
    color-primary: $color-golden,
    color-primary-dark: #cc8600,
    color-primary-darkest: #cc8600,
    color-primary-light: #ffe6a6,
    color-primary-lightest: $color-golden-lightest,
    color-font: $color-white
  )
);

$theme-map: null;

@mixin themify() {
  @each $theme, $map in $themes {
    .theme-#{"" + $theme} & {
      $theme-map: () !global;
      @each $key, $submap in $map {
        $value: map-get(map-get($themes, $theme), '#{$key}');
        $theme-map: map-merge(
          $theme-map,
          (
            $key: $value
          )
        ) !global;
      }

      @content;
      $theme-map: null !global;
    }
  }
}

@function themed($key) {
  @return map-get($theme-map, $key);
}

// background-color

.bg-color--primary {
  @include themify() {
    background-color: themed('color-primary');
  }
}

.bg-color--primary-lightest {
  @include themify() {
    background-color: themed('color-primary-lightest');
  }
}

.bg-color--primary-light {
  @include themify() {
    background-color: themed('color-primary-light');
  }
}

.bg-color--white {
  background-color: $color-white;
}

// border-color

.b-color--primary {
  @include themify() {
    border-color: themed('color-primary');
  }
}

.b-color--primary-light {
  @include themify() {
    border-color: themed('color-primary-light');
  }
}

// color

.color--white {
  color: $color-white;
}

.color--light-gray {
  color: $color-gray-light;
}

.color--dark-gray {
  color: $color-gray-dark;
}

.color--primary {
  @include themify() {
    color: themed('color-primary');
  }
}

.color--primary-dark {
  @include themify() {
    color: themed('color-primary-dark');
  }
}

.color--primary-darkest {
  @include themify() {
    color: themed('color-primary-darkest');
  }
}
