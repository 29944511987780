@import '/src/style/variables';
@import '/src/style/mixins';

.home {
  position: relative;

  &__logo {
    display: inline-flex;
    flex-direction: column;
    left: 50%;
    max-width: 400px;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: all $timing-base;
    width: 65%;

    &--anim {
      top: 33%;
      transform: translate(-50%, -50%) scale(0.9);

      @include tablet {
        top: 40%;
      }

      @include desktop {
        top: 40%;
      }
    }
  }

  &__about {
    position: absolute;
    top: 1rem;
    right: 1rem;
    opacity: 0;
    transition: opacity $timing-base;

    &--anim {
      opacity: 1
    }
  }

  &__actions {
    bottom: 0;
    left: 50%;
    opacity: 0;
    position: absolute;
    transform: translateX(-50%);
    transition: all $timing-base;

    @include tablet {
      top: 60%;
    }

    @include desktop {
      top: 60%;
    }

    &--anim {
      opacity: 1;
    }
  }
}
