@import '/src/style/variables';
@import '/src/style/themes.module';
@import '/src/style/mixins';

.game-topics {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  transition: all $timing-fast;

  &--hidden {
    top: calc(100% - 2.5em);
  }
}

.shake {
  animation: shake 1s;
  animation-iteration-count: infinite;
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg) scale(0.9);
  }
  10% {
    transform: translate(-1px, -2px) rotate(-1deg) scale(0.9);
  }
  20% {
    transform: translate(-2px, 0px) rotate(1deg) scale(0.9);
  }
  30% {
    transform: translate(2px, 2px) rotate(0deg) scale(0.9);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg) scale(0.9);
  }
  50% {
    transform: translate(-1px, 2px) rotate(-1deg) scale(0.9);
  }
  60% {
    transform: translate(-2px, 1px) rotate(0deg) scale(0.9);
  }
  70% {
    transform: translate(2px, 1px) rotate(-1deg) scale(0.9);
  }
  80% {
    transform: translate(-1px, -1px) rotate(1deg) scale(0.9);
  }
  90% {
    transform: translate(1px, 2px) rotate(0deg) scale(0.9);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg) scale(0.9);
  }
}
