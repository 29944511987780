@tailwind base;
@tailwind components;
@tailwind utilities;

@import './themes.module';

@layer components {
  .cntnr {
    @apply bg-white rounded-xl shadow-lg;

    &--top-rounded {
      @apply rounded-b-none;
    }
  }

  .modal-header {
    @apply text-lg font-bold text-center;
  }
}

html,
body {
  font-family: 'Roboto', arial, sans-serif;
  height: 100%;
  -webkit-font-smoothing: antialiased;
}

#root {
  height: 100%;
}

input,
textarea,
button,
select,
a {
  -webkit-tap-highlight-color: transparent;
}
