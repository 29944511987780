// size

$size-xxs: 0.375em;
$size-xs: 0.5em;
$size-s: 0.875em;
$size-base: 1.125em;
$size-large: 1.5em;
$size-xlarge: 2em;

// timing

$timing-fast: 250ms;
$timing-base: 500ms;
$timing-slow: 1000ms;
