@import '/src/style/variables';
@import '/src/style/themes.module';

// reset

input[type='text'] {
  background-color: inherit;
  border: initial;
  color: inherit;
  display: block;
  font-family: inherit;
  font-size: inherit;
  width: 100%;
  appearance: none;

  &:focus {
    outline: none;
  }
}

// basic

.text-input__group {
  position: relative;
}

input[type='text'] {
  @include themify() {
    border: 2px solid themed('color-primary');
  }
  border-radius: $size-xxs;
  padding: $size-s $size-base;
  font-size: $size-base;
  font-weight: 600;
  line-height: normal;
  transition: all $timing-fast;

  &::placeholder {
    color: transparent;
    font-weight: normal;
  }

  &:disabled {
    @include themify() {
      background-color: rgba($color: $color-gray-light, $alpha: 0.1);
      border-color: rgba($color: themed('color-primary'), $alpha: 0.1);
    }
  }

  &:focus {
    &::placeholder {
      color: $color-gray-light;
    }

    &.without-placeholder {
      &::placeholder {
        color: transparent;
      }
    }

    @include themify() {
      background-color: rgba($color: themed('color-primary'), $alpha: 0.1);
    }
  }

  &:focus,
  &:not(:placeholder-shown) {
    + .text-input__label {
      @include themify() {
        color: themed('color-primary');
      }
      font-weight: 600;
      transform: translate(-$size-base + $size-xxs, -$size-xlarge) scale(0.78);
    }
  }

  &:disabled + .text-input__label {
    color: rgba($color: $color-gray-light, $alpha: 0.5);
  }

  &.with-placeholder {
    &::placeholder {
      color: $color-gray-light;
    }
  }
}

.text-input__label {
  color: $color-gray-light;
  display: block;
  font-size: $size-base;
  line-height: normal;
  margin-left: $size-base;
  margin-top: calc(-1 * $size-base / 2);
  position: absolute;
  top: 50%;
  transform-origin: 0 0;
  transition: all $timing-fast;
}
